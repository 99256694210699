import { collection, getDocs } from 'firebase/firestore';
import regionNames from '../items/regionNames';
import { db } from '../config';

export const handleCardClick = async (name, clickedRegion, setModalInfo, setActiveTab, setError) => {
    try {
        const regionCollection = collection(db, regionNames[clickedRegion - 1]);
        const regionSnapshot = await getDocs(regionCollection);
        const cityData = regionSnapshot.docs.find(doc => doc.data().name === name).data();
        setModalInfo({ visible: true, data: cityData });
        setActiveTab('info');
    } catch (err) {
        setError('Қала деректерін алу сәтсіз аяқталмады');
    }
};