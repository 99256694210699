import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/MenuBar.css';

function MenuBar({ isMenuExpanded, setIsMenuExpanded }) {
  return (
    <div className={`menu_bar ${isMenuExpanded ? 'expanded' : ''}`} onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
      <i class="fa-solid fa-bars"></i>
      {isMenuExpanded && (
        <div className="expanded_menu">
          <Link to="/add">
            <i class="fa-solid fa-plus"></i>
          </Link>
          <Link to="/help">
            <i class="fa-solid fa-circle-info"></i>
          </Link>
        </div>
      )}
    </div>
  );
}

export default MenuBar;