import React, { useEffect, useState } from 'react';
import '../styles/HelpScreen.css';
import { db } from '../config';
import { collection, getDocs } from 'firebase/firestore';
import FeedbackForm from './FeedbackForm';
import { useSwipeable } from 'react-swipeable';

const HelpScreen = () => {
    const [helpData, setHelpData] = useState([]);
    const [activeTab, setActiveTab] = useState('aboutUs');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHelpData = async () => {
            try {
                const helpCollection = collection(db, 'help');
                const helpSnapshot = await getDocs(helpCollection);
                const helpList = helpSnapshot.docs.map(doc => doc.data());
                setHelpData(helpList);
            } catch (error) {
                console.error('Error fetching help data:', error);
                setError('Error fetching help data. Please try again later.');
            }
        };

        fetchHelpData();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleFeedbackSubmit = (feedback) => {
        console.log('Feedback submitted:', feedback);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            const nextTab = activeTab === 'feedback' ? 'aboutUs' : activeTab === 'aboutUs' ? 'privacy' : 'feedback';
            setActiveTab(nextTab);
        },
        onSwipedRight: () => {
            const prevTab = activeTab === 'aboutUs' ? 'feedback' : activeTab === 'privacy' ? 'aboutUs' : 'privacy';
            setActiveTab(prevTab);
        },
    });

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    return (
        <div className="help-screen" {...swipeHandlers}>
            <header className="map-header">Көмек</header>
            <div className="tab_buttons">
                <button className={activeTab === 'aboutUs' ? 'active' : ''} onClick={() => handleTabClick('aboutUs')}>Біз туралы</button>
                <button className={activeTab === 'privacy' ? 'active' : ''} onClick={() => handleTabClick('privacy')}>Құпиялылық саясаты</button>
                <button className={activeTab === 'feedback' ? 'active' : ''} onClick={() => handleTabClick('feedback')}>Пікірлер</button>
            </div>
            <div className="tab-content">
                {error && <p className="error">{error}</p>}
                {helpData.length > 0 ? (
                    <div className='help-background'>
                        {activeTab === 'aboutUs' && (
                            <ul>
                                {helpData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item.aboutUsText.map((text, textIndex) => (
                                            isValidUrl(text) ? (
                                                <li key={textIndex}><img className='help-screen-img' src={text} alt={`About Us ${textIndex}`} /></li>
                                            ) : (
                                                <li key={textIndex}>{text}</li>
                                            )
                                        ))}
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                        {activeTab === 'privacy' && (
                            <ul>
                                {helpData.map((item, index) => (
                                    <li key={index}>{item.privacyText}</li>
                                ))}
                            </ul>
                        )}
                        {activeTab === 'feedback' && (
                            <div className="video-container">
                                <FeedbackForm onSubmit={handleFeedbackSubmit} />
                            </div>
                        )}
                    </div>
                ) : (
                    !error && <p>Көмек ақпараты жүктелуде...</p>
                )}
            </div>
        </div>
    );
};

export default HelpScreen;