import React, { useState, useEffect, useRef, useCallback } from 'react';
import DangerMessage from '../components/DangerMessage';
import ModalMap from '../components/ModalMap';
import MenuBar from '../components/MenuBar';
import MapScroller from '../components/MapScroller';
import { fetchRegions } from '../functions/fetchRegions';
import { handleRegionClick } from '../functions/handleRegionClick';
import { handleCardClick } from '../functions/handleCardClick';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';

function Home() {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [clickedRegion, setClickedRegion] = useState(null);
  const [regionNamesList, setRegionNamesList] = useState([]);
  const [modalInfo, setModalInfo] = useState({ visible: false, data: null });
  const [activeTab, setActiveTab] = useState('main');
  const [headerText, setHeaderText] = useState('ziyarat.kz');
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dangerMessage, setDangerMessage] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const markerRef = useRef(null);

  useEffect(() => {
    fetchRegions(setRegions, setLoading, setError);
  }, []);

  useEffect(() => {
    if (error) {
      setDangerMessage(error);
    }
  }, [error]);
  const toggleExpand = (id) => setExpandedIndex(expandedIndex === id ? null : id);
  const closeModal = () => setModalInfo({ visible: false, data: null });

  const buildRoute = useCallback(async () => {
    try {
      const getUserLocation = () => new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
            (error) => reject(error)
          );
        } else {
          reject(new Error('Geolocation is not supported by this browser.'));
        }
      });

      const userLocation = await getUserLocation();
      const [lat, lng] = modalInfo.data.point.split(',').map(Number);
      const destination = { lat, lng };
      const directionsService = new window.google.maps.DirectionsService();

      const result = await new Promise((resolve, reject) => {
        directionsService.route({
          origin: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
          destination: new window.google.maps.LatLng(destination.lat, destination.lng),
          travelMode: window.google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            resolve(response);
          } else {
            reject(`Directions request failed due to ${status}`);
          }
        });
      });

      return result;
    } catch (error) {
      console.error('Error building route:', error);
      return false;
    }
  }, [modalInfo.data]);

  const handleRouteClick = async () => {
    const routeBuilt = await buildRoute();
    if (routeBuilt) {
      navigate('/new-route', { state: { modalInfo } });
    } else {
      setError('Бағыт құру сәтсіз аяқталды');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'info':
        return (
          <>
            <h2>Аңыздар</h2>
            {modalInfo.data.aniz && modalInfo.data.aniz.length > 0 ? (
              modalInfo.data.aniz.map((aniz) => (
                <div key={aniz.id} className={`aniz-item ${expandedIndex === aniz.id ? 'expanded' : ''}`}>
                  <h3 onClick={() => toggleExpand(aniz.id)} className="aniz-atauy">
                    {aniz.atauy}
                  </h3>
                  <div className={`aniz-details ${expandedIndex === aniz.id ? 'show' : ''}`}>
                    <p>{aniz.aniz}</p>
                    {aniz.audio && (
                      <audio controls src={aniz.audio}>
                        Браузеріңіз аудио элементті қолдамайды.
                      </audio>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>Аңыз мәліметтері жоқ</p>
            )}
            <h2>Зерттеулер</h2>
            {modalInfo.data.zertteuler && modalInfo.data.zertteuler.length > 0 ? (
              modalInfo.data.zertteuler.map((item, index) => (
                <div key={index}>
                  <br />
                  <p>{item}</p>
                </div>
              ))
            ) : (
              <p>Зерттеулер мәліметтері жоқ</p>
            )}
          </>
        );
      case 'main':
        return (
          <div>
            <h2>{modalInfo.data.name}</h2>
            <div className="images-container">
              {modalInfo.data.images.map((image, idx) => (
                <img key={idx} src={image} alt={`${modalInfo.data.name} ${idx + 1}`} />
              ))}
            </div>
            <p>{modalInfo.data.description}</p>
          </div>
        );
      case 'route':
        return (
          <div>
            <button className="route-button" onClick={handleRouteClick}>Бағыт</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="home">
      <header className="map-header">{headerText}</header>
      <MapScroller
        regions={regions}
        clickedRegion={clickedRegion}
        handleRegionClick={(regionNumber) => handleRegionClick(
          regionNumber, clickedRegion, setClickedRegion, setRegionNamesList, setHeaderText, setError
        )}
        regionNamesList={regionNamesList}
        handleCardClick={(name) => handleCardClick(name, clickedRegion, setModalInfo, setActiveTab, setError)}
        markerRef={markerRef}
      />

      {modalInfo.visible && (
        <ModalMap
          modalInfo={modalInfo}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          closeModal={closeModal}
          renderTabContent={renderTabContent}
        />
      )}

      <MenuBar
        isMenuExpanded={isMenuExpanded}
        setIsMenuExpanded={setIsMenuExpanded}
      />

      {dangerMessage && (
        <DangerMessage
          message={dangerMessage}
          onClose={() => setDangerMessage('')}
        />
      )}

      {isLoading && <div>Жүктелуде...</div>}
    </div>
  );
}

export default Home;