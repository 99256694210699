import React from 'react';
import regionImages from '../items/regionImages';
import '../styles/MapScroller.css'

function MapScroller({ regions, clickedRegion, handleRegionClick, regionNamesList, handleCardClick, markerRef }) {
    return (
        <div className={`map-scroller ${clickedRegion ? 'blurred' : ''}`}>
            {regions.map((region, index) => (
                <img
                    key={region.id}
                    src={regionImages[index]}
                    alt={`Region ${index + 1}`}
                    className={`region region${index + 1} ${index === 0 ? 'rounded' : ''} ${clickedRegion === index + 1 ? 'clicked zoomed' : ''} ${clickedRegion && clickedRegion !== index + 1 ? 'blur' : ''}`}
                    onClick={() => handleRegionClick(index + 1)}
                />
            ))}

            {clickedRegion && (
                <div className="marker" ref={markerRef}>
                    <div className="cards-container">
                        {regionNamesList.map((item, idx) => (
                            <div className="card" key={idx} onClick={() => handleCardClick(item.name)}>
                                <img src={item.images[0]} alt={`${item.name}`} className="card-image" />
                                <div className='card_text_content'>
                                    <p className='name'>{item.name}</p>
                                    <p className="description">
                                        {item.description ? (item.description.length > 25 ? `${item.description.substring(0, 25)}...` : item.description) : 'Сипаттама табылмады'}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MapScroller;