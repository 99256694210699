import React from 'react';
import '../styles/ModalMap.css';

function ModalMap({ activeTab, setActiveTab, closeModal, renderTabContent }) {
    return (
        <div className="modal_map">
            <div className="modal_content_map">
                <span className="close" onClick={closeModal}>&times;</span>
                <div className="modal_tabs">
                    <button className={`modal_tab ${activeTab === 'info' ? 'active' : ''}`} onClick={() => setActiveTab('info')}>
                        <i class="fa-solid fa-info"></i>
                    </button>
                    <button className={`modal_tab ${activeTab === 'main' ? 'active' : ''}`} onClick={() => setActiveTab('main')}>
                        <i class="fa-solid fa-house"></i>
                    </button>
                    <button className={`modal_tab ${activeTab === 'route' ? 'active' : ''}`} onClick={() => setActiveTab('route')}>
                        <i class="fa-solid fa-route"></i>
                    </button>
                </div>
                <div className="tab_content">
                    {renderTabContent()}
                </div>
            </div>
        </div>
    );
}

export default ModalMap;