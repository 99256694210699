import { collection, getDocs } from 'firebase/firestore';
import regionNames from '../items/regionNames';
import { db } from '../config';

// Function to check if cached data exists
const getCachedRegions = () => {
    const cachedRegions = localStorage.getItem('regions');
    return cachedRegions ? JSON.parse(cachedRegions) : null;
};

// Function to cache fetched data locally
const cacheRegions = (regions) => {
    localStorage.setItem('regions', JSON.stringify(regions));
};

export const fetchRegions = async (setRegions, setLoading, setError) => {
    try {
        setLoading(true);

        // Step 1: Check if regions are already cached
        const cachedRegions = getCachedRegions();
        if (cachedRegions) {
            setRegions(cachedRegions);
            setLoading(false);
            return; // Skip fetching from Firestore
        }

        // Step 2: Fetch regions from Firestore if not cached
        const fetchedRegions = await Promise.all(regionNames.map(async (name, index) => {
            const regionCollection = collection(db, name);
            const regionSnapshot = await getDocs(regionCollection);
            const regionData = regionSnapshot.docs.map(doc => doc.data())[0];
            return { id: index + 1, name, status: regionData?.status || 'Белгісіз' };
        }));

        // Step 3: Cache fetched regions
        cacheRegions(fetchedRegions);
        
        // Step 4: Update state with fetched regions
        setRegions(fetchedRegions);
    } catch (err) {
        setError('Аймақ деректерін алу сәтсіз аяқталды');
    } finally {
        setLoading(false);
    }
};