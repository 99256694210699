import Region1 from '../svg/01.svg';
import Region2 from '../svg/02.svg';
import Region3 from '../svg/03.svg';
import Region4 from '../svg/04.svg';
import Region5 from '../svg/05.svg';
import Region6 from '../svg/06.svg';
import Region7 from '../svg/07.svg';
import Region8 from '../svg/08.svg';
import Region9 from '../svg/09.svg';
import Region10 from '../svg/10.svg';
import Region11 from '../svg/11.svg';
import Region12 from '../svg/12.svg';
import Region13 from '../svg/13.svg';
import Region14 from '../svg/14.svg';
import Region15 from '../svg/15.svg';
import Region16 from '../svg/16.svg';
import Region17 from '../svg/17.svg';

import '../styles/regionImages.css';

const regionImages = [
    Region1, Region2, Region3, Region4, Region5, Region6, Region7,
    Region8, Region9, Region10, Region11, Region12, Region13,
    Region14, Region15, Region16, Region17
];

export default regionImages;